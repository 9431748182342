import loadable from '@loadable/component'

const routes = [
  {
    path: '/course-center',
    exact: true,
    component: loadable(() => import('@/view/index/index')),
  },
  {
    path: '/about-us',
    exact: true,
    component: loadable(() => import('@/view/about-us/about-us')),
  },
]

export default routes
