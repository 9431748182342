import loadable from '@loadable/component'

const routes = [
  {
    path: '*',
    exact: true,
    component: loadable(() => import('@/view/index/index')),
  },
]

export default routes
