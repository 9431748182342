import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import routes from './router/routes'
import routes404 from './router/404'

const App =()=> {
  const arr = routes.map(item => item.path);
  let newRoutes = routes;
  try {
    if (!arr.includes(window.location.pathname)) {
      newRoutes = routes404;
    }
  } catch (error) {
    console.error('Error:', error);
  }
  return (
    <Router>
      {newRoutes.map((route) => (
        <Route key={route.path} {...route} />
      ))}
    </Router>
  )
}

export default App
